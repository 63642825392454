a {
  align-items:center;
  background: linear-gradient(to bottom, rgb(55, 55, 55, .5) 0%, rgba(30, 41, 49, 0.5) 100%) ;
  border-image: linear-gradient(to bottom, rgb(55, 55, 55, 1), rgb(255, 225, 148,.25)) 1 / 3px;
  border-style: solid;
  border-width: 3px;
  box-shadow: 0 3px 3px #262a31;
  display: grid;
  grid-template-columns: 1fr 3fr;
  grid-template-rows: 1fr;
  transition: 200ms;
  text-decoration: none;
  column-gap: 3em;  
  overflow: hidden;
}


a img{
  width: auto;
  max-height: 10rem;
  margin-left: 20%;
  grid-row: 1 / 2;
  z-index: 1;

}

.menu-item-text {
  color: #FFE194;
  display: flex;
  font-size: 3em;
  text-transform: uppercase;
  margin: auto 0;
  grid-column: 2;
  grid-row: 1 / 2;
  z-index: 2;
  font-weight: bold;
}

.back-deco {
  animation-duration: 0.3s;
  animation-name: init-lines-hover-reverse;
  border-right:#FFE194 3px solid;
  height: 100%;
  opacity: 0;
  transition: 400ms;
  margin: 0 1em 0 3em;
  grid-row:1 / 2;
  grid-column: 1 / 3;
  z-index: 3;
}

a:hover {
  background: linear-gradient(180deg, rgba(55,55,55,0) 0%, rgba(255,225,148,1) 100%);
  border-image: linear-gradient(to top, #373737, #FFE194) 1 / 3px;
  border-style: solid;
  border-width: 3px;
  box-shadow: 0 5px 7px black;
  cursor: pointer;
  margin-top:-3px;
  transition: 200ms;
}
a:focus {
  background: linear-gradient(180deg, rgba(55,55,55,1) 0%, rgba(255,225,148,.5) 100%);
  border-image: linear-gradient(to top, #373737, #FFE194) 1 / 3px;
  border-style: solid;
  border-width: 3px;
  box-shadow: 0 5px 7px black;
  cursor: pointer;
  margin-top:-3px;
  transition: 200ms;
}
a:active {
  background: none;
  border-image: linear-gradient(to top, #FFE194, #FFE194) 1 / 3px;
  border-style: solid;
  border-width: 3px;
  box-shadow: 0 3px 3px black;
  cursor: pointer;
  margin-top: 3px;
  transition: 200ms;
}
a:hover img {
  opacity: 1;
  transition: 400ms;
}
a:hover .back-deco {
  animation-duration: 0.3s;
  animation-name: init-lines-hover;
  opacity: 1;
  visibility: visible;
}
a:hover .menu-item-text{
  color: #373737;
}
a:active .menu-item-text{
  color: #FFE194;
}
a:focus img {
  opacity: 1;
  transition: 400ms;
}
a:focus .back-deco {
  animation-duration: 0.3s;
  animation-name: init-lines-hover;
  opacity: 1;
  visibility: visible;
}
a:active img {
  opacity: 1;
  transition: 400ms;
}
a:active .back-deco {
  animation-duration: 0.3s;
  animation-name: init-lines-hover;
  opacity: 1;
  visibility: visible;
}


@keyframes init-lines-hover {
0% {
  height: 0;
  opacity: 0;
}
100% {
  height: 100%;
  opacity: 1;
}
}

@keyframes init-lines-hover-reverse {
0% {
  height: 100%;
  opacity: 1;
}
100% {
  height: 0;
  opacity: 0;
}
}

@media (min-width: 1024px) {
  a {
    align-items:center;
    background-color: transparent;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 3fr 1fr;
    transition: 200ms;
    text-decoration: none;
        height: 100%;
    width: auto;
  }

  a img{
    width: auto;
    max-height: 70%;
    grid-column: 1;
    grid-row: 1;
    justify-self: center;
    margin: 0;
  }

  .menu-item-text {
    color: #FFE194;
    display: flex;
    font-size: 1.5em;
    text-transform: uppercase;
    grid-column: 1;
    grid-row: 2;
    padding: 0 10% 0 10%;
    justify-content: center;
  }
  .back-deco {
    animation-duration: 0.3s;
    animation-name: init-lines-hover-reverse;
    border-left:#FFE194 3px solid;
    border-right:#FFE194 3px solid;
    height: 100%;
    opacity: 0;
    transition: 400ms;
    margin: 0 1em 0 1em;
    grid-row:1 / 3;
    grid-column: 1;
  }

}