.app {
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  font-family: 'Merriweather Sans';
  font-family: 'Barlow';
  font-weight: 500;
  font-size: 1.15em;
  position: relative;
  height: 100%;
  z-index: 2;
  scrollbar-color: #ffe19470 #0622373d;
  scrollbar-width: 0.7em;
}
::-webkit-scrollbar{
  width: .7em;
}
::-webkit-scrollbar-track{
  background-color: #0622373d;
}
::-webkit-scrollbar-thumb{
  background: linear-gradient(to bottom, rgba(55,55,55,0) 0%, rgba(255, 225, 148, 0.397) 100%);
  transition: .3s;
  cursor: pointer;
}
.page-title{
  color:#FFE194;
  grid-column: 1;
  font-weight: lighter;
  font-size: 2em;
  padding: 10px;
  animation-name: init-title;
  animation-duration: 0.3s;
  animation-name: init-crumbs;
  animation-duration: 1.2s;
  justify-self: flex-start;
  margin-left: calc(0px - 10px);
}
.page-title-img{
  align-self: center;
}
.main-page-logo{
  position: absolute;
  top:4%;
  width: 30%;
  animation-name: init-logo;
  animation-duration: 1.2s;
}
.main-page-logo-bottom  {
  position: absolute;
  bottom:4%;
  width: 30%;
  animation-name: init-logo2;
  animation-duration: 1.2s;

}

#legend{
  display: none;
  top:auto;
  bottom:5%;
  font-size: 1em;
}

.empty{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color:#ffe194;
  grid-row: 1/-1;
  grid-column: 1/-1;
  row-gap: 30px;

}
.empty img{
  height: 200px;
}
.nav{
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: 1fr;
  width: 80%;
  align-items: center;
  position: absolute;
  top:5%;
}
.breadcrumbs {
  grid-column: 2;
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: auto;
  align-items: center;
  justify-self: flex-end;
  margin: 0;
  justify-items: flex-end;
  column-gap: 10px;
  padding: 10px;
  transition: 300ms;
  animation-name: init-crumbs;
  animation-duration: 1.2s;
  z-index: 10;
  height: 3em;
  position: absolute;
  font-size: 1em;
  margin-right: calc(0px - 1em - 3px);
  font-weight: bold;
}
.breadcrumbs a, .breadcrumbs .breadcrumbs-divider, .breadcrumbs #breadcrumbs-home{
  text-decoration: none;
  color:#FFE194;
  text-align: center;
  margin: 0;
  border: 0;
  box-shadow: none;
  display: grid;
  grid-template-rows: 1fr;
  margin: 10px;
  border: 3px #37373700 solid;
  background: none;
  padding: 0 10px;
}
.breadcrumbs .breadcrumbs-divider{
  padding: 0;
  margin: 10px 0;
}
.breadcrumbs #active{
  background-color: #FFE194;
  color:#373737;
}
.breadcrumbs a:hover, 
.breadcrumbs a:active,
.breadcrumbs a:focus, 
.breadcrumbs #active:hover,
.breadcrumbs #active:active,
.breadcrumbs #active:focus {
  margin: 10px;
  animation: none;
  box-shadow: none;
  border-image: none;
  transition: 300ms;
}
.breadcrumbs a:hover {
  background: none;
  border: 3px #ffe194 solid;
}
.breadcrumbs a:focus {
  background: none;
  border: 3px #ffe1948f solid;
}
.breadcrumbs-divider{
  height: 1em;
}

#bread-planes{
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: 1fr;
  width: 80%;
  align-items: center;
  position: absolute;
  top:5%;
}

#bread-planes .breadcrumbs{
  grid-column: 1 / 3;
  justify-self: center;
}



.help{
  position: absolute;
  bottom:5%;
  transition: 300ms;
  animation-name: init-help;
  animation-duration: 1.2s;
  color:#FFE194;
  border-top: 1px solid;
  border-image: linear-gradient(to left,rgba(55, 55, 55, 0),#FFE194, rgba(55, 55, 55, 0)) 1 1 1 1;
white-space: pre-line;
  font-weight: lighter;
  padding: 1em 100px 0 0;
  font-size: .9em;
  text-align: center;
  line-height: 1.5;
  opacity: .7;
}

@keyframes init-crumbs {
  0% {
      opacity: 0;
      margin-top: 50px;
    }
  100% {
      opacity: 1;
      margin-top: 0;
    }
}
@keyframes init-help {
  0% {
      opacity: 0;
    }
  100% {
      opacity: .7;
    }
}


@keyframes init-title {
  0% {
      opacity: 0;

    }
  100% {
      opacity: 1;

    }
}

@keyframes init-logo {
  0% {
    opacity: 0;
    margin-top: -30px;
  }
  40% {
    opacity: 0;
    margin-top: -30px;
    }
  100% {
      opacity: 1;
      margin-top: 0;
    }
}
@keyframes init-logo2 {
  0% {
    opacity: 0;
    margin-bottom: -30px;
  }
  40% {
    opacity: 0;
    margin-bottom: -30px;
    }
  100% {
      opacity: 1;
      margin-bottom: 0;
    }
}

@media (max-width: 1180px) {
  .breadcrumbs{
    font-size: .8em;
  }
  .nav{
    top:4%;
  }
  .main-page-logo{
    top:5%;
    width: 30%;
  }
  .main-page-logo-bottom{
    bottom:5%;
    width: 30%;
  }
}
