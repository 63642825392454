.loading{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    grid-template-rows: 1fr;
    transition: 300ms;
    position: relative;
}
.loading #intro{
    position: absolute;
    height: 500px;
    width: 500px;
    transition: 300ms;
    animation-name: init-intro;
    animation-duration: 1s;
    animation-delay: .5s;
    animation-fill-mode: forwards;
    opacity: 0;

}
.loading #cloud{
    position: absolute;
    animation-name: init-cloud;
    animation-duration: 1s;
    animation-delay: .5s;
    animation-fill-mode: forwards;
    height: auto;
    width: 1000px;
    opacity: 0;
    transition: 300ms;
}
.loading span{
    grid-row: 1;
    grid-column: 1;
    text-align: center;
    padding: 50px;
    font-size: 3em;
    font-weight: lighter;
    color:#FFE194;
}

.lazy{
  display: none;
  color:#FFE194;
  flex-direction: column;
  align-self: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  height: 100%;
  animation-name: init-lazy;
  animation-duration: 1.2s;
  white-space: pre-line;
  font-size: 1.5em;
}

@keyframes init-lazy {
  0% {
      opacity: 0;
      margin-top: 50px;
    }
  100% {
      opacity: 1;
      margin-bottom: 0;
    }
}

@keyframes init-intro {
    0% {
        margin-top: 400px;
        opacity: 0;
      }
    75% {
      margin-top: 0;
        opacity: 1;
      }
    100% {
        margin-top: -400px;
        opacity: 0;
      }
}

@keyframes init-cloud {
    0% {
        opacity: 0;
        margin:0;
      }
    50% {
        opacity: .2;
        margin: 0;
      }
    75% {
        opacity: .2;
        margin: 0;
      }
    100% {
        opacity: 0;
        margin-top: -400px;
      }
}

@media (orientation: portrait) {
  .app{
    display: none;
  }
  .lazy{
    display: flex;
  }
}
@media (max-width:1024px) {
  .app{
    display: none;
  }
  .lazy{
    display: flex;
  }
}