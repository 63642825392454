.gods-menu {
  align-content:center;
  animation-duration: 1.2s;
  animation-name: init-menu;
  display:grid;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr 1fr;
  justify-content: center;
  min-width: 80%;
  min-height: 70%;
  max-width: 80%;
  max-height: 70%;
  grid-column: 2;
  column-gap: 1em;
}
.gods-list{
  display: grid;
  grid-template-rows: 1fr 1fr;
  grid-auto-columns: minmax(130px,20%);
  align-items: center;
  justify-content: center;
  width: 100%;
  gap:10px;
  background: linear-gradient(to top, rgba(55, 55, 55, 0), #3b40497e);
}
.gods-list .god-container{
  display: grid;
  flex-direction: column;
  transition: 300ms;
  align-items: center;
  height: 100%;
  width: 100%;
  border-image: linear-gradient(to top, #474747, #474747) 1 / 3px;
  border-style: solid;
  border-width: 3px;
  box-shadow: 0 3px 3px black;
  grid-template-rows: 60% 40%;
}

.gods-list .god-name{
  text-align: center;
  align-self: flex-start;
  color: #FFE194;
  font-size: 1.1em;
  justify-self: center;
  font-weight: lighter;
  padding: 20px 5px 0 5px;
  display: flex;
  flex-direction: column;
}
.gods-list .god-container img{
  height: 150px;
  justify-self: center;
  align-self: flex-end;
  margin-top: 20px;
}

#img-tim {
  grid-row: 1 / 3;
  grid-column: 1;
}
#img-vigsla {
  grid-row:1;
}
#img-caertana {
  grid-row:1;
}
#img-juvena {
  grid-row:1;
}
#img-roja {
  grid-row:2;
}
#img-tjon {
  grid-row: 2;
}
#img-fraed {
  grid-row: 2;
}
#img-firmi {
  grid-row: 1;
  grid-column: 5;
}
#img-aedis {
  grid-row: 2;
  grid-column: 5;
}

#img-tim-active {
  grid-row: 1 / 3;
  grid-column: 1;
}
#img-vigsla-active {
  grid-row:1;
}
#img-caertana-active {
  grid-row:1;
}
#img-juvena-active {
  grid-row:1;
}
#img-roja-active {
  grid-row:2;
}
#img-tjon-active {
  grid-row: 2;
}
#img-fraed-active {
  grid-row: 2;
}
#img-firmi-active {
  grid-row: 1;
  grid-column: 5;
}
#img-aedis-active {
  grid-row: 2;
  grid-column: 5;
}

#img-tim-active,
#img-vigsla-active,
#img-caertana-active,
#img-juvena-active,
#img-roja-active,
#img-tjon-active,
#img-fraed-active,
#img-firmi-active,
#img-aedis-active
{
  background: none;
  border-image: linear-gradient(to top, transparent, #FFE194) 1 / 3px;
  border-style: solid;
  border-width: 3px;
  box-shadow: 0 3px 3px black;
  cursor: pointer;
  margin-top: 3px;
  transition: 200ms;
}
#img-tim-active:hover .god-name,
#img-vigsla-active:hover .god-name,
#img-caertana-active:hover .god-name,
#img-juvena-active:hover .god-name,
#img-roja-active:hover .god-name,
#img-tjon-active:hover .god-name,
#img-fraed-active:hover .god-name,
#img-firmi-active:hover .god-name,
#img-aedis-active:hover .god-name
{
  color:#FFE194;
}

@keyframes init-menu {
0% {
    margin-top: 100px;
    opacity: 0;
  }
100% {
    margin-top: 0;
    opacity: 1;
  }
    }


.gods-list .god-container:hover{
  cursor: pointer;
  transition: 300ms;
  background: linear-gradient(to top, rgb(255, 225, 148) 0%, rgba(55,55,55,1) 100%);
  border-image: linear-gradient(to top, #373737, #FFE194) 1 / 3px;
  border-style: solid;
  border-width: 3px;
  margin-top:-7px;
}
.gods-list .god-container:hover .god-name{
  color: #373737;
}
.gods-list .god-container:hover .god-name{
  color: #373737;
}
.gods-list .god-container:focus {
  background: linear-gradient(180deg, rgba(55,55,55,1) 0%, rgba(255,225,148,.5) 100%);
  border-image: linear-gradient(to top, #373737, #FFE194) 1 / 3px;
  border-style: solid;
  border-width: 3px;
  box-shadow: 0 5px 7px black;
  cursor: pointer;
  margin-top:-3px;
  transition: 200ms;
  color:#FFE194;
}
.gods-list .god-container:active {
  background: none;
  border-image: linear-gradient(to top, #FFE194, #FFE194) 1 / 3px;
  border-style: solid;
  border-width: 3px;
  box-shadow: 0 3px 3px black;
  cursor: pointer;
  margin-top: 3px;
  transition: 200ms;
  color:#FFE194;
}
.gods-list .god-container:active .god-name{
  color:#FFE194;
}
.god-name span{
  font-weight: bold;

}

.god-name #god-title{
  font-weight: lighter;
  font-size: .85em;
  padding-top: 5px;
  white-space:pre-line;
}




.gods-details{
  background: linear-gradient(to top, rgba(55, 55, 55, 0), #3b40497e);
  color:#FFE194;
  transition: 200ms;
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
}
.gods-detail-content{
  overflow-y: auto;
}
.details-name{
  text-transform: uppercase;
  font-size: 1.6em;
  font-weight: bold;
  background-color: #3b4049;
  line-height: 2;
  position: sticky;
  top:0%;
  display: flex;
  align-items: center;
  padding: 1em;
}
.details-alias{
  color:#ffe1947c;
}
.details-descr{
  padding: 2em 1em 2em 1em;
  text-align: justify;
  white-space: pre-line;
  line-height: 1.5;
}
.details-domains{
  height: 100%;
  align-self: flex-start;
  line-height: 1.5;
}
.details-worship{
  height: 100%;
  align-self: flex-start;
  line-height: 1.5;
}
.details-planes{
  height: 100%;
  align-self: flex-start;
  line-height: 1.5;
}
.details-special-title{
  font-weight: bold;
  text-align: left;

  border-bottom: 1px solid;
  border-image: linear-gradient(to left,rgba(55, 55, 55, 0),#FFE194) 1 1 1 1;
}
.details-special-text{
  display: flex;
  height: 100%;
  word-break: break-word;
}
.details-special-text img{
  height: 100px;
}

.gods-details .empty img{
  position: static;
  opacity:1;
  filter:none;
  height: 200px;
  width: auto;
}


@keyframes init-lines-hover-maps {
  0% {
    height: 0;
    opacity: 0;
  }
  100% {
    height: 100%;
    opacity: 1;
  }
  }
  
  @keyframes init-lines-hover-reverse-maps {
  0% {
    height: 100%;
    opacity: 1;
  }
  100% {
    height: 0;
    opacity: 0;
  }
  }

  @media (max-width: 1400px) {
    .gods-menu {
      grid-template-columns: 1fr 2fr;
    }
    .gods-list .god-container img{
      height: 100px;
      grid-row: 1;
      align-self: center;
      padding: 10px;
      margin: 0;
    }
    .gods-list .god-container .god-name{
      grid-row: 1;
      padding: 0;
      align-self: center;
      text-align: left;
      justify-self: flex-start;
      align-self: center;
    }
    .gods-list .god-container:hover{
      margin-top: 0;
      margin-left: 7px;
      background: linear-gradient(to left, rgb(255, 225, 148) 0%, rgba(55,55,55,1) 100%);
      border-image: linear-gradient(to left, #373737, #FFE194) 1 / 3px;
      color:#FFE194;
    }

    .gods-list .god-container{
      display: grid;
      grid-template-columns: 1fr 3fr;
      grid-template-rows: 1fr;
      width: initial;
    }
    .gods-list .god-container:active{
      margin: 0;
      background: #373737;
      border: 3px solid #FFE194;
    }
    .gods-list .god-container:hover{
      color: #FFE194;
    }
    .gods-details img{
      height: 300px;
    }
    .gods-list{
      display: grid;
      grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
      grid-template-columns: 1fr;
      align-items: flex-start;
      justify-content: center;
      width: 100%;
      gap:0;
      row-gap:10px;
      overflow-y: auto;
      padding-right: 10px;
      height: 100%;
    }
    .gods-details{
      font-size:1em;
    }
    #img-tim, #img-tim-active {
      grid-row: 1;
      grid-column: 1;
    }
    #img-vigsla,#img-vigsla-active {
      grid-row:2;
      grid-column: 1;
    }
    #img-caertana, #img-caertana-active{
      grid-row:3;
      grid-column: 1;
    }
    #img-juvena, #img-juvena-active {
      grid-row:4;
      grid-column: 1;
    }
    #img-roja,#img-roja-active {
      grid-row:5;
      grid-column: 1;
    }
    #img-tjon, #img-tjon-active{
      grid-row: 6;
      grid-column: 1;
    }
    #img-fraed, #img-fraed-active{
      grid-row: 7;
      grid-column: 1;
    }
    #img-firmi, #img-firmi-active{
      grid-row: 8;
      grid-column: 1;
    }
    #img-aedis, #img-aedis-active{
      grid-row: 9;
      grid-column: 1;
    }
    #img-tim-active,
    #img-vigsla-active,
    #img-caertana-active,
    #img-juvena-active,
    #img-roja-active,
    #img-tjon-active,
    #img-fraed-active,
    #img-firmi-active,
    #img-aedis-active
    {
      margin: 0;
      color: #FFE194;
      border-image: linear-gradient(to left, #373737, #FFE194) 1 / 3px;
      border-style: solid;
      border-width: 3px;
    }
    #img-tim-active:hover,
    #img-vigsla-active:hover,
    #img-caertana-active:hover,
    #img-juvena-active:hover,
    #img-roja-active:hover,
    #img-tjon-active:hover,
    #img-fraed-active:hover,
    #img-firmi-active:hover,
    #img-aedis-active:hover
    {
      margin: 0;
    }
}