.empires-menu {
  align-content:flex-start;
  animation-duration: 1.2s;
  animation-name: init-menu;
  display:grid;
  grid-template-rows: 1fr;
  grid-template-columns: 2fr 5fr;
  justify-content: center;
  min-width: 80%;
  min-height: 70%;
  max-width: 80%;
  max-height: 70%;
  grid-column: 2;
  column-gap: 10px;
}
.empires-list{
  display: grid;
  grid-auto-rows: 130px;
  grid-template-columns: 1fr;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  row-gap:10px;
  overflow-y: auto;
  background: linear-gradient(to top, rgba(55, 55, 55, 0), #3b40497e);
}

.empires-menu .empires-container, .empires-container-active{
  display: grid;
  flex-direction: column;
  grid-template-columns: 120px 2fr;
  grid-template-rows: 1fr;
  transition: 300ms;
  align-items: center;
  height: 100%;
  border-image: linear-gradient(to top, #474747, #474747) 1 / 3px;
  border-style: solid;
  border-width: 3px;
  box-shadow: 0 3px 3px black;
}
.empires-container-active {
  cursor: pointer;
  transition: 300ms;
  border-image: linear-gradient(to left, #373737, #FFE194) 1 / 3px;
  border-style: solid;
  border-width: 3px;
  background: none;
}

.empires-container-active img{
  width: 100px;
  justify-self: center;
  align-self: center;
}



.empires-menu .god-name{
  text-align: left;
  align-self: center;
  color: #FFE194;
  font-size: 1.3em;
  justify-self: flex-start;
  font-weight: lighter;
  display: grid;
  flex-direction: row;
  padding-right: 1em;
}
.empires-menu .empires-container img{
  width: 100px;
  justify-self: center;
  align-self: center;
}

@keyframes init-menu {
0% {
    margin-top: 100px;
    opacity: 0;
  }
100% {
    margin-top: 0;
    opacity: 1;
  }
    }



.empires-list .empires-container:hover{
  background: linear-gradient(90deg, rgba(55,55,55,1) 0%, rgba(255,225,148,1) 100%);
  border-image: linear-gradient(to left, #373737, #FFE194) 1 / 3px;
  border-style: solid;
  border-width: 3px;
  box-shadow: 0 5px 7px black;
  cursor: pointer;
  transition: 200ms;
  margin-left: 7px;
}
.empires-list .empires-container:focus {
  background: linear-gradient(180deg, rgba(55,55,55,1) 0%, rgba(255,225,148,.5) 100%);
  border-image: linear-gradient(to top, #373737, #FFE194) 1 / 3px;
  border-style: solid;
  border-width: 3px;
  box-shadow: 0 5px 7px black;
  cursor: pointer;
  margin-top:-3px;
  transition: 200ms;
}
.empires-list .empires-container:active {
  background: none;
  border-image: linear-gradient(to top, #FFE194, #FFE194) 1 / 3px;
  border-style: solid;
  border-width: 3px;
  box-shadow: 0 3px 3px black;
  cursor: pointer;
  transition: 200ms;
  margin: 0;
}
.god-name span{
  font-weight: lighter;
}

.god-name #god-title{
  font-weight: lighter;
  font-size: .7em;
}

@keyframes init-lines-hover-maps {
  0% {
    height: 0;
    opacity: 0;
  }
  100% {
    height: 100%;
    opacity: 1;
  }
  }
  
  @keyframes init-lines-hover-reverse-maps {
  0% {
    height: 100%;
    opacity: 1;
  }
  100% {
    height: 0;
    opacity: 0;
  }
  }

 .empire-details{
  background: linear-gradient(to top, rgba(55, 55, 55, 0), #3b40497e);
  color:#FFE194;
  padding: 1.5em;
  transition: 200ms;
  display: grid;
  grid-template-columns: 280px 1fr;
  grid-auto-rows: auto;
  column-gap: 20px;
  row-gap: 10px;
  overflow-y: auto;
  white-space: pre-line;
  line-height: 1.5em;
  align-items: flex-start;
  justify-content: center;
}
.empire-details .details-name{
  text-transform: uppercase;
  font-size: 1.6em;
  font-weight: 500;
  color: #FFE194;
  grid-row: 2;
  justify-self: center;
  justify-content: center;
  background: none;
  text-align: center;
  width: 100%;
  align-self: flex-end;
  line-height: 1.5;
  padding: 0;
}
.empire-details .details-domains{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}
#emp-logo-details{
  height: 250px;
  width: auto;
  grid-column: 1;
  grid-row: 1;
  position: static;
  justify-self: center;
}
.details-keywords{
  display: flex;
  flex-direction: column;
  justify-self: center;
  justify-content: center;
  grid-row: 4;
  grid-column: 1;
  row-gap: 10px;
  width: 100%;
  align-self: flex-end;
}
.details-keyword{
  padding: 10px 0;
  background: linear-gradient(to right, white, #FFE194);
  width: 100%;
  color:#373737;
  font-weight: bold;
  border-radius: 10px;
  text-align: center;
  text-transform: uppercase;
}
.empire-details .details-alias{
  color:#ffe1947c;
  grid-column: 1;
  grid-row: 3;
  text-transform: uppercase;
  text-align: center;
}
.empire-details .details-descr{
  padding: 0 0 1em 0;

}
.empire-details .details-features{
  height: 100%;
  grid-row: 2;
}
.details-worship{
  height: 100%;
  align-self: flex-start;
}
.details-planes{
  height: 100%;
  align-self: flex-start;
}
.details-languages{
  padding: 5px 0 40px 0;
  display: block;
}
.details-languages-hidden{
  display: none;
}
.empire-details .details-special-title{
  font-weight: bold;
  text-align: left;
  border-bottom: 1px solid;
  border-image: linear-gradient(to left,rgba(55, 55, 55, 0),#FFE194) 1 1 1 1;
  width: 100%;
  text-transform: uppercase;
  margin-top: 0;
}
.empire-details .details-feature{
  border-image: linear-gradient(to top, #474747, #474747) 1 / 3px;
  border-style: solid;
  border-width: 3px;
  padding: 1em;
  margin-top: 5px;
  background-color: #3338418a;
  box-shadow: 0 3px 3px black;
}
.empire-details .details-feature li{
  margin-top: 5px;
}
.empire-details .details-feature-name{
  font-weight: bold;
}
.empire-details .details-variant{
  background-color: #262a31;
  color:#FFE194;
  padding: 2em;
  transition: 200ms;
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 2em;
  white-space: pre-line;
  line-height: 1.5;
  border-left: 1px solid #FFE194;
}
.empire-details .details-special-text{
  display: flex;
  height: 100%;
  word-break: break-word;
  padding-right: 1em;
  text-align: justify;
}
.details-special-text img{
  height: 100px;
}
.details-empire-descr{
  display: grid;
  grid-template-columns: 1fr;
  overflow-y: auto;
  align-items: flex-start;
  align-self: flex-start;
  height: 100%;
  grid-row: 1 / 5;
}

@media (max-width: 1180px) {
  #emp-logo-details{
    height: 150px;
  }
  .empire-details{
    grid-template-columns: 200px 1fr;
  }
  .empire-details .details-name{
    font-size: .9em;
  }
  .empire-details .details-domains{
    font-size: .9em;
  }
  .empire-details .details-keyword{
    font-size: .7em;
    padding: 5px 0;
  }
  .empires-menu .god-name{
    font-size: .9em;
  }
  .empires-menu .empires-container img{
    width: 80px;
  }
}