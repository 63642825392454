.about-container{
    color:#FFE194;
    align-content:flex-start;
    animation-duration: 1.2s;
    animation-name: init-menu;
    display:grid;
    grid-template-rows: 1fr auto;
    grid-template-columns: 1fr;
    justify-content: center;
    min-width: 80%;
    min-height: 70%;
    max-width: 80%;
    max-height: 70%;
    grid-column: 2;
    row-gap: 1em;
}
.about-slide{
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr;
    column-gap: 20px;
}
.about-slide-block{
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom, rgba(55, 55, 55, 0), #3b40497e);
}
.about-slider-dots{
    grid-row: 2;
    width: 100%;
    display: flex;
    flex-direction: row;
    column-gap: 1em;
    justify-content: center;
    cursor: pointer;
    transition: 300ms;
}
.about-slider-dots{
    grid-row: 2;
    width: 100%;
    display: flex;
    flex-direction: row;
    column-gap: 1em;
    justify-content: center;
    cursor: pointer;

}
.slider-dot{
    width: 30px;
    height: 30px;
    background: linear-gradient(to top, rgba(55, 55, 55, 0), #3b40497e);
    border-image: linear-gradient(to bottom, rgb(55, 55, 55, 1), rgb(255, 225, 148,.25)) 1 / 3px;
    border-style: solid;
    border-width: 3px;
    transition: 300ms;
}
.slider-dot:hover{
    background: none;
    background-color: #FFE194;
    transition: 300ms;
}
.slider-dot-active{
    width: 30px;
    height: 30px;
    background: linear-gradient(to top, rgba(55, 55, 55, 0), #3b40497e);
    border-image: linear-gradient(to bottom, rgb(55, 55, 55, 1), rgb(255, 225, 148)) 1 / 3px;
    border-style: solid;
    border-width: 3px;
    transition: 300ms;
}
