.lore-menu {
  align-content:center;
  animation-duration: 1.2s;
  animation-name: init-menu;
  display:grid;
  grid-auto-flow: row;
  grid-auto-rows: 1fr;
  grid-template-columns: 1fr;
  justify-content: center;
  min-width: 80%;
  min-height: 70%;
  max-width: 80%;
  max-height: 70%;
  grid-column: 2;
}

.lore-menu a{
  position: relative;
  grid-template-rows: 90px;
  grid-template-columns: 120px 1fr;
}

.lore-menu a img{
  grid-row: 1;
  grid-column: 1;
  height: auto;
  width: 100%;
}

.lore-menu .menu-item-text {
  grid-column: 2;
  justify-content: center;
  text-align: left;
  grid-row: 1;
  z-index: 2;
  position: absolute;
  padding: 10px 0;  
  align-self: flex-end;
  font-size: 1em;
  height: 1em;
  padding: 10px 0;
}

.lore-menu .back-deco {
  animation: none;
  animation-duration: 0.3s;
  animation-name: init-lines-hover-reverse-maps;
  grid-row: 1 / 3;
  border:0;
  border-left:#FFE194 3px solid;
  border-right:#FFE194 3px solid;
  margin: 0 1em 0 1em;
  width: 90%;
  height: 100%;
  justify-self: center;
  align-self: flex-end;
  display: none;
}


@keyframes init-menu {
0% {
    margin-top: 100px;
    opacity: 0;
  }
100% {
    margin-top: 0;
    opacity: 1;
  }
    }
@media (min-width: 1024px) {
  .lore-menu{
    grid-auto-flow: column;
    grid-auto-columns: 25% 25% 25% 25%;
    grid-template-rows: 1fr 1fr;
    min-width: 80%;
    min-height: 70%;
    max-width: 80%;
    max-height: 70%;
    row-gap: 12px;
    column-gap: 6px;
  }
  .lore-menu a .menu-item-text{
    font-size: 1em;
    grid-row:2;
    grid-column: 1;
    text-align: center;
    width: 100%;
    height: 2em;
    padding: 0;
  }
  .lore-menu a{
    grid-template-columns: 1fr;
    grid-template-rows: auto 2em;
  }
  .lore-menu a img{
    grid-row: 1;
    max-height: 100%;
    min-height: 50%;
    max-width: 60%;
    object-fit: cover;
  }
  #history{
    grid-row: 1;
    grid-column: 2;
  }
  #gods{
    grid-row: 2;
    grid-column: 2;
    z-index: 10;
  }
  #cosmos{
    grid-row: 1;
    grid-column: 5;
  } 
  #empires{
    grid-row: 2;
    grid-column: 3;
    z-index: 10;
  }
  #lang{
    grid-row: 1;
    grid-column: 3;
  }
  #races{
    grid-row: 2;
    grid-column: 4;
    z-index: 10;
  }
  #general{
    grid-row: 1/3;
    grid-column: 1;
  }
  #general img{
    max-width: 90%;
  }
  #cosmos{
    grid-row: 1;
    grid-column: 4;
  }
}


@keyframes init-lines-hover-maps {
  0% {
    height: 0;
    opacity: 0;
  }
  100% {
    height: 100%;
    opacity: 1;
  }
  }
  
  @keyframes init-lines-hover-reverse-maps {
  0% {
    height: 100%;
    opacity: 1;
  }
  100% {
    height: 0;
    opacity: 0;
  }
  }