.menu {
  align-items: center;
  animation-duration: 1.2s;
  animation-name: init-menu;
  display:grid;
  grid-template-rows: minmax(1fr,1fr) minmax(1fr,1fr) minmax(1fr,1fr);
  grid-template-columns: 1fr;
  justify-content: center;
  column-gap: 5px;
  min-width: 80%;
  min-height: 70%;
  max-width: 80%;
  max-height: 70%;
  grid-column: 2;
  transition: 300ms;
}
.home-lines-left{
  position: absolute;
  height: 50%;
  left:1%;
  z-index: -1;
  opacity: .5;
  animation-duration: 2s;
  animation-name: init-lines-left;
}
.home-lines-right{
  position: absolute;
  height: 50%;
  right:1%;
  z-index: -1;
  opacity: .5;
  animation-duration: 2s;
  animation-name: init-lines-right;
}
#maps, #lore, #campaign {
  grid-template-columns: 1fr;
  grid-template-rows: 4fr 1fr;
  position: relative;
}
#maps .back-deco, #lore .back-deco, #campaign .back-deco{
  width: 90%;
 justify-self: center;
  margin: 0;
}
#maps .menu-item-text, #lore .menu-item-text, #campaign .menu-item-text{
  flex-grow: 1;
  height: 100%;
  align-items: center;
}

@keyframes init-menu {
0% {
    margin-top: 100px;
    opacity: 0;
  }
100% {
    margin-top: 0;
    opacity: 1;
  }
    }
@keyframes init-lines-left {
0% {
    opacity: 0;
    left:-10%;
  }
60% {
    opacity: 0;
  }
100% {
    opacity: .5;
    left:1%
  }
}
@keyframes init-lines-right {
0% {
    opacity: 0;
    right:-10%;
  }
60% {
    opacity: 0;
  }
100% {
    opacity: .5;
    right:1%
  }
}

@media (min-width: 1024px) {
  .menu{
    grid-template-rows: 1fr;
    grid-template-columns: 1fr 1fr 1fr;
  }
  #maps img{
    max-height: 500px;
    align-self: flex-end;
  }
  #lore img{  
    max-height: 500px;
    align-self: flex-end;
  }
  #campaign img{
    max-height: 500px;
    align-self: flex-end;
  }
}
@media (max-width: 1180px) {
  .menu{
    grid-template-rows: 1fr;
    grid-template-columns: 1fr 1fr 1fr;
  }
  #maps img{
    max-height: 400px;
    align-self: flex-end;
  }
  #lore img{  
    max-height: 400px;
    align-self: flex-end;
  }
  #campaign img{
    max-height: 400px;
    align-self: flex-end;
  }
}
