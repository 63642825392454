.timeline {
  align-content:center;
  animation-duration: 1.2s;
  animation-name: init-menu;
  display:grid;
  grid-template-rows: 1fr;
  grid-template-columns: 2fr 3fr;
  justify-content: center;
  min-width: 80%;
  min-height: 70%;
  max-width: 80%;
  max-height: 70%;
  grid-column: 2;
  column-gap: 10px;
}
.events{
  display: grid;
  grid-auto-rows:90px;
  grid-template-columns: 1fr;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  overflow-y: auto;
  row-gap: 10px;
  background: linear-gradient(to top, rgba(55, 55, 55, 0), #3b40497e);
}
.events .event-container, .event-container-selected{
  display: grid;
  transition: 300ms;
  grid-template-rows: 1fr 1fr;
  grid-template-columns: 1fr;
  color:#FFE194;
  height: 100%;
  border-image: linear-gradient(to top, #474747, #474747) 1 / 3px;
  border-style: solid;
  border-width: 3px;
  box-shadow: 0 3px 3px black;
  padding: 0 30px;
  row-gap: 10px;
  margin-left: 1.5em;
}

.event-container-selected{
  border-image: linear-gradient(to left, #373737, #FFE194) 1 / 3px;
  border-style: solid;
  border-width: 3px;
  transition: 300ms;
}

.event-date{
  height: 100%;
  align-items: flex-start;
  display: flex;
  justify-content: flex-start;
  text-align: left;
  line-height: 1;
  opacity: .7;
  font-size: .8em;
}
.event-name{
  height: 100%;
  align-items: flex-end;
  display: flex;
}

@keyframes init-menu {
0% {
    margin-top: 100px;
    opacity: 0;
  }
100% {
    margin-top: 0;
    opacity: 1;
  }
    }

#asf{
  border-top: 3px solid #FFE194;
}
#age{
  background: linear-gradient(to right, #22252b, transparent);
  margin: 0;
}

.events .event-container:hover{
  background: linear-gradient(90deg, rgba(55,55,55,1) 0%, rgba(255,225,148,1) 100%);
  border-image: linear-gradient(to left, #373737, #FFE194) 1 / 3px;
  border-style: solid;
  border-width: 3px;
  box-shadow: 0 5px 7px black;
  cursor: pointer;
  transition: 200ms;
  margin-left: calc(1.5em + 7px);
}
.events .event-container:active {
  cursor: pointer;
  transition: 300ms;
  border: 3px solid #FFE194;
  background: none;
  margin-left: 1.5em;
}
#age:hover{
  background: linear-gradient(90deg, rgba(55,55,55,1) 0%, rgba(255,225,148,1) 100%);
  border-image: linear-gradient(to left, #373737, #FFE194) 1 / 3px;
  border-style: solid;
  border-width: 3px;
  margin-left: 7px;
}

#age:active{
  margin-left: 0;
  border-image: linear-gradient(to left, #FFE194, #FFE194) 1 / 3px;
  border-style: solid;
  border-width: 3px;
  background: linear-gradient(to right, #22252b, transparent);
}


.events-details{
  background: linear-gradient(to top, rgba(55, 55, 55, 0), #3b40497e);
  color:#FFE194;
  transition: 200ms;
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
  white-space: pre-line;
}
.history-content{
  overflow-y: auto;
}
.events-details .details-name{
  text-transform: uppercase;
  font-size: 1.6em;
  font-weight: bold;
}
.details-alias{
  color:#ffe1947c;
  padding: 2em 2em 1em 2em;
}
#alias-history{
  white-space: normal;
  text-align: right;
}
.events-details .details-descr{
  padding: 0 0 1em 0;
  line-height: 2;
  hyphens:auto;
  padding: 0 2em 2em 2em;
}
.details-domains{
  height: 100%;
  align-self: flex-start;
}
.details-worship{
  height: 100%;
  align-self: flex-start;
}
.details-planes{
  height: 100%;
  align-self: flex-start;
}
.details-special-title{
  font-weight: bold;
  text-align: left;
  margin-top: -20px;
}
.details-special-text{
  display: flex;
  height: 100%;
  padding: 10px 0;
  word-break: break-word;
}
.details-special-text img{
  height: 100px;
}



@keyframes init-lines-hover-maps {
  0% {
    height: 0;
    opacity: 0;
  }
  100% {
    height: 100%;
    opacity: 1;
  }
  }
  
  @keyframes init-lines-hover-reverse-maps {
  0% {
    height: 100%;
    opacity: 1;
  }
  100% {
    height: 0;
    opacity: 0;
  }
  }

  @media (max-width: 1180px) {
    .events{
      font-size: .8em;
    }
    .events-details .details-name{
      font-size: 1em;
    }
    .events-details .details-descr{
      font-size: .9em;
    }
}