.maps-menu {
 
  grid-column: 2;
  grid-row: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  animation-duration: 1.2s;
  animation-name: init-menu;
}

.maps-menu a{
  grid-template-columns: 1fr;
}

.maps-menu a:active .menu-item-text{
  color:#FFE194;
}

.maps-menu a img{
  grid-row: 1;
  max-height: 100%;
  min-height: 50%;
  max-width: 60%;
}



.maps-menu .menu-item-text {
  font-size: 1em;
  grid-row:2;
  grid-column: 1;
  text-align: center;
  width: 100%;
  height: 2em;
  padding: 0;
}

.maps-listing{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-auto-rows: 400px;
  width: 100%;
  height: 100%;
  column-gap: 6px;
  row-gap: 6px;
  margin-top: 4px;
  overflow-y: scroll;
  padding-top: 4px;
}


.maps-menu a .back-deco {
  display: none;
}

#unknown{
  border: 3px solid #373737;
  pointer-events: none;
  transition: none;
}

#unknown .menu-item-text{
  color:hsla(43, 100%, 79%, 0.239);
}

@keyframes init-menu {
  0% {
      margin-top: 100px;
      opacity: 0;
    }
  100% {
      margin-top: 0;
      opacity: 1;
    }
      }


@media (min-width: 1024px) {
  .maps-menu{
    min-width: 80%;
    max-width: 80%;
    min-height: 70%;
    max-height: 70%;
  }
  .maps-menu a{
    height: unset;
  }
  .maps-menu img{
    grid-row: 1;
    max-height: 100%;
    min-height: 50%;
    max-width: 60%;
    object-fit: cover;
  }
}



