.party{
    align-content:flex-start;
    animation-duration: 1.2s;
    animation-name: init-menu;
    display:grid;
    grid-template-rows: 1fr;
    grid-template-columns: 2fr 3fr;
    justify-content: center;
    min-width: 80%;
    min-height: 70%;
    max-width: 80%;
    max-height: 70%;
    grid-column: 2;
    column-gap: 10px;
    position: relative;
}


.party-recap{
    text-align: justify;
    overflow-y: auto;
}
#intro-ps{
    height: 120px;
    grid-row: 1;
    grid-column: 1;
    opacity: 1;
    animation-name: init-status;
    animation-duration: 2s;
    animation-delay: .5s;
    animation-iteration-count: infinite;
    position: absolute;
    opacity: 0;
    transform: rotate(45deg);
}
@keyframes init-status {
    0% {
        margin-top: 15px;
        opacity: 0;
      }
    75% {
      margin-top: 0;
        opacity: 1;
      }
    100% {
        margin-top: -10px;
        opacity: 0;
      }
}
.ps-icon-status-bad{
    display:grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    grid-row: 2;
    grid-column: 5/7;
    justify-items: center;
    position: relative;
}
#ps-sonya{
    grid-row: 1;
    grid-column: 1;
}
#ps-aedis{
    grid-row: 1;
    grid-column: 2/4;
}
#ps-bronx{
    grid-row: 1;
    grid-column: 4/6;
}
#ps-firmi{
    grid-row: 1;
    grid-column: 6/8;
}
#ps-samar{
    grid-row: 2;
    grid-column: 3/5;
}
.party-status{
    display: grid;
    grid-template-columns: 60px 60px 60px 60px 60px 60px 60px 60px;
    align-items: center;
    justify-content: center;
    padding: 0 0 2em 0;
    display: none;
}
.ps-character{
    padding: 10px;
    text-align: center;
    width: 100%;
}

.ps-icon{
    height: 120px;
}
.ps-name{
 
    text-transform: uppercase;
    padding: 10px;
}
.ps-class{
    color:#ffe1947c;
    padding: 0 10px 10px 10px;
}

.ps-status{
    color: #373737;
    font-weight: bold;
    padding: 10px;
    background-color: #FFE194;
}

.ps-status-unknown{
    padding: 10px 0;
    background: linear-gradient(to right, #373737, #ffe1947c);
    width: 100%;
    color:#FFE194;
    font-weight: bold;
    border-radius: 10px;
    text-align: center;
    text-transform: uppercase;
}

.quests{
    grid-column: 1;
    grid-row: 1;
    overflow-y: hidden;
    align-items: flex-start;
    display: flex; 
    flex-direction: column;
    width: 100%;
    height: 100%;
}


.clues-contacts{
    
    grid-column: 2;
    grid-row: 1;
    overflow-y: hidden;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    height: 100%;
}
.clues-bullet-subtitle{
    color:#ffe1947c;
    padding-bottom: 10px;
    padding-top: 5px;
    margin-top: -5px;
}
.party-tabs{
    display: flex;
    flex-direction: row;
    justify-content: stretch;
    position: sticky;
    top: 0;
    z-index: 1000;
    border-bottom: 3px solid #FFE194;
    width: 100%;
}
.party-tab, .party-tab-active{
    text-transform: uppercase;
    font-size: 1em;
    background-color: #3b4049;
    border-radius:15px 15px 0 0;
    font-weight: bold;
    cursor: pointer;
    padding: 10px 20px;
    margin:3px 1.5px -3px 1.5px;
    height: 2em;
    display: flex;
    align-items: center;
    color:#FFE194;
    width: 100%;
    justify-content: center;
    border-top: 3px solid transparent;
    border-left: 3px solid transparent;
    border-right: 3px solid transparent;
}

.party-tab:hover{
    background-color:#ffe19480;
    transition: 300ms;
}
.party-tab-active{
    border: 3px solid #FFE194;
    color:#FFE194;
}
.party-header{
    color: #FFE194;
    padding: 1em;
    font-size: 1.6em;
    position: sticky;
    top:0%;
    font-weight: bold;
    text-transform: uppercase;
    background-color: #3b4049;
    z-index: 100;
    width: calc(100% - 2em);
    box-shadow: 0 3px 5px rgb(26, 26, 26);
}
#text-recap{
    margin: 0;
}
.quest-container{
    padding: 1em;
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.5);
    color:#FFE194;
    margin: 10px;
}
.clues-container{
    border-left: 3px #474747 solid;
    padding: 1em;
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.5);
    border-left: 12px #3b4049 solid;
    color:#FFE194;
    margin: 5px 10px 5px 10px;
}
.quest-list{
    overflow-y: scroll;
    height: 100%;
}
.quest-container{
    display: grid;
    grid-template-columns: 48px 1fr;
    box-shadow: 0 3px 3px black;

}
.quest-marker{
    grid-column: 1;
    width: 48px;
    height: auto;
    grid-row: 1 / 5;
    margin-left: -.5em;
}
.quest-name, .clues-name{
    font-weight: bold;
    text-transform: uppercase;
    padding: 0 0 10px 0;
    font-size: 1.2em;
    color:#FFE194;
    grid-column: 2;
}
#subtitle-recap{
    text-align: right;
    padding-right: calc(1em - 5px);
    padding-top: 1rem;
    white-space: pre-line;
}
.quest-participants{
    color: #ffe1947c;
    padding: 0 0 10px 0;
    grid-column: 2;
    margin-top: -5px;
}
.quest-objective{
    padding: 10px 0 10px 0;
    grid-column: 2;
    line-height: 1.75;
    white-space: pre-line;
}
.quest-description{
    font-style: italic;
    border-top: 1px solid;
    border-image: linear-gradient(to left,rgba(55, 55, 55, 0),#FFE194) 1 1 1 1;
    padding-top: 10px;
    grid-column: 2;
    text-align: justify;
    line-height: 1.5;
    white-space: pre-line;
    color: #ffe1947c;
}
.clues-bullet-name{
    font-weight: bold;
    padding: 5px 0;
}
.clues-bullet-descr{
    line-height: 1.5;
    text-align: justify;
    padding-top: 10px;
    white-space: pre-line;
}

.party-content{
    color:#FFE194;
    overflow-y: auto;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    margin-top:5px;
}
#text-recap{
    border: none;
    padding: 1em;
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.5);
    color:#FFE194;
    margin: 10px;
}
.party-schedule{
    display:grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
}
.schedule-block{
    border-left: none;
    padding: 10px;
    color:#FFE194;
    font-size: 1em;
    text-align: center;
    display: none;

}
.schedule-block-title{
    border-left: none;
    padding: 10px;
    color:#FFE194;
    font-size: 1em;
    text-transform: uppercase;
    font-weight: bold;
    border-bottom: 1px solid;
    border-image: linear-gradient(to right,rgba(55, 55, 55, 0),#FFE194, rgba(55, 55, 55, 0)) 1 1 1 1;
}
.schedule-block-content{
    border-left: none;
    padding: 10px;
    color:#FFE194;
    font-size: 1.2em;
    white-space: pre-line;
    text-transform: uppercase;
}
#next-game{
    grid-row:1;
    grid-column: 1/3;
}
.schedule-block .details-keyword{
    padding-top:2em;
    padding-bottom: 2em;
    background: none;
    color:#FFE194;
    background-color: #3b4049 ;
}


@media (max-width: 1180px) {
    .party{
        font-size: .9em;
    }
}