.collapsable-container{
  border-left: 3px #474747 solid;
  padding: 1em;
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.5);
  border-left: 12px #3b4049 solid;
  color:#FFE194;
  margin: 5px 10px 5px 10px;
  cursor: pointer;
  transition: 300ms;
  border-radius: 0 0 1em 1em;
}
#collapsable-basic{
  border-left: 6px solid #3b4049;
  border-radius: 0;
}

.clues-bullet-descr-collapsable{
  line-height: 1.5;
  text-align: justify;
  padding-top: 1em;
  margin-top: calc(1em - 10px);
  white-space: pre-line;
  border-top: 1px solid;
  border-image: linear-gradient(to right,rgba(55, 55, 55, 0),#FFE194, rgba(55, 55, 55, 0)) 1 1 1 1;
}
#collapsable-basic .clues-bullet-descr-list{
  border-top: 0px;
}
.collapsable-clickable-rich{
  display: grid;
  grid-template-columns: 1fr 1em;
  grid-template-rows: 1fr 1fr;

}
.collapsable-clickable-simple{
  display: grid;
  grid-template-columns: 1fr 1em;
  grid-template-rows: 1fr;

}
.collapsable-clickable-rich .clues-bullet-name{
  grid-column: 1;
  grid-row: 1;
}
.collapsable-clickable-rich .clues-bullet-subtitle{
  grid-column: 1;
  grid-row: 2;
}
.collapsable-clickable-rich .collapsable-arrow{
  height: 1.5em;
  margin-left:-10px;
  grid-row: 1;
  align-self: center;
  transform: rotate(90deg);
  transition: 300ms;
}
.collapsable-clickable-rich .collapsable-arrow-expanded{
  height: 1.5em;
  margin-left:-10px;
  grid-row: 1;
  align-self: center;
  transform: rotate(270deg);
  transition: 300ms;
}
.collapsable-clickable-simple .clues-bullet-name{
  grid-column: 1;
  grid-row: 1;
}
.collapsable-clickable-simple .clues-bullet-descr{
  line-height: 1.5;
  text-align: justify;
  padding-top: 3em;
  white-space: pre-line;
}
.collapsable-clickable-simple .collapsable-arrow{
  height: 1.5em;
  margin-left:-10px;
  grid-row: 1;
  align-self: center;
  transform: rotate(90deg);
  transition: 300ms;
}
.collapsable-clickable-simple .collapsable-arrow-expanded{
  height: 1.5em;
  margin-left:-10px;
  grid-row: 1;
  align-self: center;
  transform: rotate(270deg);
  transition: 300ms;

}



.collapsable-clickable-rich-with-icon{
  display: grid;
  grid-template-columns: auto 1fr 1em;
  grid-template-rows: 1fr 1fr;
  column-gap: 1em;
}
.collapsable-clickable-rich-with-icon .clues-bullet-name{
  grid-column: 2;
  grid-row: 1;
  padding-top:0;
  align-self: flex-end;
}
.collapsable-clickable-rich-with-icon .clues-bullet-subtitle{
  grid-column: 2;
  grid-row: 2;
}
.collapsable-clickable-rich-with-icon  .collapsable-arrow{
  height: 1.5em;
  margin-left:-10px;
  grid-row: 1 / 3;
  align-self: center;
  transform: rotate(90deg);
  transition: 300ms;
}
.collapsable-clickable-rich-with-icon  .collapsable-icon{
  height: 5em;
  grid-row: 1 / 3;
  grid-column: 1;
  align-self: flex-start;
}
.collapsable-clickable-rich-with-icon  .collapsable-arrow-expanded{
  height: 1.5em;
  margin-left:-10px;
  grid-row: 1 / 3;
  align-self: center;
  transform: rotate(270deg);
  transition: 300ms;
}
.clues-bullet-descr-rich-with-icon{
  line-height: 1.5;
  text-align: justify;
  padding-top: 10px;
  white-space: pre-line;
  margin-left: 6em;
  border-top: 1px solid;
  border-image: linear-gradient(to right,rgba(55, 55, 55, 0),#FFE194, rgba(55, 55, 55, 0)) 1 1 1 1;
}
.clues-bullet-descr-list{
  line-height: 1.5;
  text-align: justify;
  padding-top: 10px;
  white-space: pre-line;
}
.clues-bullet-descr-list:nth-child(2){
  border-top: 1px solid;
  border-image: linear-gradient(to right,rgba(55, 55, 55, 0),#FFE194, rgba(55, 55, 55, 0)) 1 1 1 1;
}

@media (max-width: 1180px) {
  .collapsable-container{
      font-size: .9em;
  }
}