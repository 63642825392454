.races {
  align-content:center;
  animation-duration: 1.2s;
  animation-name: init-menu;
  display:grid;
  grid-template-rows: auto 1fr;
  grid-template-columns: 1fr 2fr;
  justify-content: center;
  min-width: 80%;
  min-height: 70%;
  max-width: 80%;
  max-height: 70%;
  grid-column: 2;
  column-gap: 1em;
}
.races-list{
  display: grid;
  grid-auto-rows:100px;
  grid-template-columns: 1fr;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  overflow-y: auto;
  row-gap: 10px;
  background: linear-gradient(to top, rgba(55, 55, 55, 0), #3b40497e);
  padding-right: 10px;
  grid-column: 1;
}
#appear{
  height: calc(100% + 12px);
  width: calc(100% + 10px);
  padding-top: 0;
  border: none;
  padding-bottom: 12px;

}
.races-list .races-container, .races-container-active, .races-list .races-container-appear{
  display: grid;
  transition: 300ms;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr;
  color:#FFE194;
  height: 100%;
  border-image: linear-gradient(to top, #474747, #474747) 1 / 3px;
  border-style: solid;
  border-width: 3px;
  box-shadow: 0 3px 3px black;
  padding-left: 30px;
  font-size: 1.3em;
  cursor: pointer;
}

.races-container-active{
  border-image: linear-gradient(to left, #373737, #FFE194) 1 / 3px;
  border-style: solid;
  border-width: 3px;
  transition: 300ms;
}
.races-list .races-container-appear{
  transition: 300ms;
}

.races-name{
  padding-right: 20px;
  height: 100%;
  align-items: center;
  display: flex;
  justify-content: flex-start;
  text-align: right;
}
.event-name{
  padding-left: 20px;
  height: 100%;
  align-items: center;
  display: flex;
}

@keyframes init-menu {
0% {
    margin-top: 100px;
    opacity: 0;
  }
100% {
    margin-top: 0;
    opacity: 1;
  }
    }

#asf{
  border-top: 3px solid #FFE194;
}

.races-list .races-container:hover, .races-container-appear:hover{
  background: linear-gradient(90deg, rgba(55,55,55,1) 0%, rgba(255,225,148,1) 100%);
  border-image: linear-gradient(to left, #373737, #FFE194) 1 / 3px;
  border-style: solid;
  border-width: 3px;
  box-shadow: 0 5px 7px black;
  cursor: pointer;
  transition: 200ms;
  margin-left: 7px;
}
.races-list .races-container:active,.races-container-appear:active {
  cursor: pointer;
  transition: 300ms;
  border: 3px solid #FFE194;
  background: none;
  margin: 0;
}




.races-details{
  background: linear-gradient(to top, rgba(55, 55, 55, 0), #3b40497e);
  color:#FFE194;
  transition: 200ms;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  white-space: pre-line;
  line-height: 1.5em;
  align-items: flex-start;
  overflow-x: hidden;
  overflow-y: hidden;
  grid-row: 1/3;
  grid-column: 2;
}
.races-details .details-name, .details-name-active{
  text-transform: uppercase;
  font-size: 1em;
  background-color: #3b4049;
  border-radius:15px 15px 0 0;
  font-weight: bold;
  cursor: pointer;
  padding: 10px 20px;
  margin:3px 1.5px -3px 1.5px;
  height: 2em;
  display: flex;
  align-items: center;
  color:#FFE194;
  width: 100%;
  justify-content: center;
  border-top: 3px solid transparent;
  border-left: 3px solid transparent;
  border-right: 3px solid transparent;
}
.details-alias{
  color:#ffe1947c;
}
.races-details .details-descr{
  padding: 2em 1em .5em 1em;
}
.races-divider{
  padding-top: 10px;
}
.variant-warning{
  margin: 2em 1em 0 1em;
  background-color:  #FFE194;
  color:#373737;
  font-style: italic;
  font-weight: 500;
  padding: .5em 2em;
  border-radius: 1em 0 1em 0;
  align-items: center;
  display: flex;
  flex-direction: row;
  column-gap: 1em;
  font-size: 1.1em;
}
.variant-warning img{
  height: 3em;
}
.variant-warning span{

}
.details-features{
  height: 100%;
  align-self: flex-start;
  overflow-y: auto;
  width: 100%;
}
.details-worship{
  height: 100%;
  align-self: flex-start;
}
.details-planes{
  height: 100%;
  align-self: flex-start;
}
.details-language{
  background-color: #FFE194;
  color:#373737;
  padding: 10px;
  font-weight: bold;
}
.details-languages-hidden{
  display: none;
}
.races-details .details-special-title{
  font-weight: bold;
  text-align: left;
  margin: 10px 0;
  border-bottom: 1px solid;
  border-image: linear-gradient(to left,rgba(55, 55, 55, 0),#FFE194) 1 1 1 1;
}
.races-details .details-feature{
  border-image: linear-gradient(to top, #474747, #474747) 1 / 3px;
  border-style: solid;
  border-width: 3px;
  padding: 1em;
  margin-top: 5px;
  background-color: #3338418a;
  box-shadow: 0 3px 3px black;
}
.races-details .details-feature li{
  margin-top: 5px;
}
.races-details .details-feature-name{
  font-weight: bold;
  padding-bottom: 10px;
}
.races-details .details-variant{
  background-color: #262a31;
  color:#FFE194;
  padding: 2em;
  transition: 200ms;
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 2em;
  white-space: pre-line;
  line-height: 1.5em;
  border-left: 1px solid #FFE194;
}

.races-details ul{
  padding-inline-start: 1em;
  list-style: square;
  margin-block-end:auto;
  margin-block-start: auto;
  padding-top:10px;
}

#appearances{
  margin-bottom: 2em;
}
#warning-appear{
  margin: 1em;
}
.appear-container{
  display: grid;
  grid-template-columns: 1fr;
  width: 100%;
  overflow-y: auto;
  align-self: flex-start;
  justify-self: flex-start;
  justify-content: flex-start;
}
.details-special-text{

  display: flex;
  height: 100%;

  word-break: break-word;
}
.details-special-text img{
  height: 100px;
}
.details-tabs{
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  position: sticky;
  top: 0;
  z-index: 1000;
  border-bottom: 3px solid #FFE194;
  width: 100%;
}
.races-details .details-name:hover{
  background-color:#ffe19480;
  transition: 300ms;
}
.races-details .details-name-active{
  border: 3px solid #FFE194;
  color:#FFE194;
}
.details-language-container{
  display: flex;
  flex-direction: row;
  column-gap:5px;
  padding-bottom: 1em;
}


@keyframes init-lines-hover-maps {
  0% {
    height: 0;
    opacity: 0;
  }
  100% {
    height: 100%;
    opacity: 1;
  }
  }
  
  @keyframes init-lines-hover-reverse-maps {
  0% {
    height: 100%;
    opacity: 1;
  }
  100% {
    height: 0;
    opacity: 0;
  }
  }

  @media (max-width: 1180px) {
    .races-details{
      font-size: .9em;
    }
    .races-name{
      font-size: .8em;
    }
    .variant-warning{
      font-size: .9em;
    }
}