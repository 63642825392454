html, body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue','Merriweather Sans',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: rgb(55,55,55);
  background: linear-gradient(56deg, rgba(55,55,55,1) 0%, #062237 100%);
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
  overscroll-behavior:none;
  position:fixed;
  background-size: 200%;
  animation: gradient-anim 10s ease infinite;
}
#root{
  height: 100%;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
@keyframes gradient-anim {
  0% {
      background-position: 0% 50%;
    }
  50% {
      background-position: 100% 50%;
    }
  100% {
      background-position: 0% 50%;
    }
}