.planes {
  align-items:center;
  animation-duration: 1.2s;
  animation-name: init-menu;
  display:grid;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr;
  justify-content: center;
  min-width: 80%;
  min-height: 70%;
  max-width: 80%;
  max-height: 70%;
  grid-column: 2;
  justify-self: flex-start;
  padding: 0 10px;
  pointer-events: none;
  transition: .3s;
}/*
.planes:hover {
  min-width: auto;
  min-height: 100%;
  max-width: auto;
  max-height: 100%;
  transition: .3s;
}*/
.plane-map{
  height: 100%;
  animation-duration: 1.2s;
  animation-name: init-menu;
  z-index: 100;
  align-self: center;
}
.plane-map-modal{
  position: absolute;
  height: 100%;
  z-index: 90;
  background-color: #373737d2;
  animation-duration: 1.2s;
  animation-name: init-modal;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

#inner{
  left: 15%;
  width: 20%;
}
#inner-edge{
  left: 30%;
}
#outer{
  left: 41%;
  width: 29%;
}
#void-edge{
  left: auto;
  right: 0%;
  width: 29%;
}

.plane-toggle{
  display: flex;
  flex-direction: row;
  column-gap: 10px;
  color:#FFE194;
  cursor: pointer;
  transition: .3s;
  border: 3px solid #FFE194;
  padding: .5em 1em;
  z-index: 101;
}
.planes-buttons{
  display:flex;
  flex-direction: row;
  position: absolute;
  bottom: 4%;
  column-gap: 10px;
}
.planes-buttons a:hover{
  margin: 0;
  background: none;
  border: 3px solid #FFE194;
}
.panel-toggle:hover{
  box-shadow: 0 5px 7px black;
}



.plane-panel{
  min-width: 80%;
  min-height: 70%;
  max-width: 80%;
  max-height: 70%;
  z-index: 100;
  position: absolute;
  transition: .3s;
  animation-duration: 1.2s;
  animation-name: init-details;
  background: linear-gradient(to top, rgb(55, 55, 55), #212835);
  display: flex;
  flex-direction: column;
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.5);
  overflow-y: hidden;
}
.panel-content{
  display: grid;
  grid-template-columns: 2fr 3fr;
  grid-template-rows: 1fr;
  width: 100%;
  height: 100%;
  overflow-y: hidden;
}
.plane-panel .panel-img-wrapper{
  padding: 0 0 0 0;
  width: calc(100% - 10px);
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}
.plane-panel .panel-img{
  width: 100%;


}

.planes-list{
  grid-column: 2;
  display: flex;
  flex-direction: column;
  padding: 1em 5px 1em 0;
  overflow-y: auto;
}
.planes-item-icon{
  width: 80px;
  height: auto;
  grid-column: 1;
  grid-row: 1/3;
  align-self: flex-start;
}
.planes-item{
  display: grid;
  grid-template-columns: 80px auto;
  grid-template-rows: auto 1fr;
  column-gap: 1em;
  color:#FFE194;
  padding: 1em;
  row-gap: 10px;
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.5);
  border-left: 12px #3b4049 solid;
  margin: 5px 10px 5px 0;
}
.planes-item-name{
  grid-column: 2;
  grid-row: 1;
  font-weight: bold;
  text-transform: uppercase;
}
.planes-item-text{
  grid-column: 2;
  grid-row: 2;
  white-space: pre-line;
  text-align: justify;
  line-height: 1.5;
}

@keyframes init-dmodal {
  0% {
      opacity: 0;
    }
  100% {
      opacity: 1;
    }
}

@keyframes init-details {
  0% {
      margin-top: 100px;
      opacity: 0;
    }
  100% {
      margin-top: 0;
      opacity: 1;
    }
      }

@media (max-width: 1180px) {
  .planes-item-text, .planes-item-name{
      font-size: .9em;
  }
}